import { memo } from 'react'
import { WatchListWithChart } from '~/modules/symbolQuote/simple'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { Styleds } from '../styleds'
import { winner98_agentStore } from '../winner98_agentStore'
import { store } from '~/pages/heineken_template/_private/store'
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss'
import { css } from '@emotion/react'
import { useSnapshot } from 'valtio'

export type StockGroupName =
  | 'winner98_stock_group_1'
  | 'winner98_stock_group_2'
  | 'winner98_stock_group_3'

const watchListGroup = (state: 1 | 2 | 3) => {
  if (state === 1) return 'winner98_stock_group_1'
  if (state === 2) return 'winner98_stock_group_2'
  if (state === 3) return 'winner98_stock_group_3'
}

export const WatchListGroupSelector = memo<ReactProps>(function WatchListGroupSelector() {
  return (
    <Styleds.PageModeContent>
      <winner98_agentStore.tabs3.WithButton to='群組1'>
        <FuiButton.Display
          onClick={event => {
            winner98_agentStore.userStockGroup = 1
          }}
        >
          群組1
        </FuiButton.Display>
      </winner98_agentStore.tabs3.WithButton>

      <winner98_agentStore.tabs3.WithButton to='群組2'>
        <FuiButton.Display
          onClick={event => {
            winner98_agentStore.userStockGroup = 2
          }}
        >
          群組2
        </FuiButton.Display>
      </winner98_agentStore.tabs3.WithButton>

      <winner98_agentStore.tabs3.WithButton to='群組3'>
        <FuiButton.Display
          onClick={event => {
            winner98_agentStore.userStockGroup = 3
          }}
        >
          群組3
        </FuiButton.Display>
      </winner98_agentStore.tabs3.WithButton>
    </Styleds.PageModeContent>
  )
})

export const Winner98_MultipleWatchListSymbol = memo<ReactProps>(
  function Winner98_MultipleWatchListSymbol() {
    const watchListGroupState = useSnapshot(winner98_agentStore)
    return (
      <div
        css={css`
          ${fill_vertical_cross_center}
          gap:4px;
        `}
      >
        <div
          css={css`
            width: 100%;
            height: 28px;
          `}
        >
          <WatchListGroupSelector />
        </div>
        <div
          css={css`
            width: 100%;
            height: calc(100% - 28px);
          `}
        >
          <WatchListWithChart
            groupName={watchListGroup(watchListGroupState.userStockGroup) as StockGroupName}
            useChart={store.charting}
          />
        </div>
      </div>
    )
  },
)
