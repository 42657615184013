import React, { memo } from 'react'
import { css } from '@emotion/react'
import { Tooltip, Fade } from '@mui/material'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { useMarketLights } from '~/modules/screener/containers/useStockScreenerResource'
import { useDatePick } from '~/modules/screener/useDatePick'
import styled from '@emotion/styled'

// type SignalValueNumber = {
//   value: 1 | 2 | 3 | 4
// }

export const TrafficLight = memo(function TrafficLight(props: { titleName: string }) {
  //const data = useFirebaseValue<SignalValueNumber>('winner98_signal', 'signal')
  const lastTradeString = useDatePick()
  const autoData = useMarketLights({ date: lastTradeString }).data
  const value = autoData ?? NaN
  return (
    <div css={container}>
      <div css={title}>{props.titleName}</div>
      <div css={lightContainer}>
        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 200 }}
          placement='top'
          followCursor={true}
          title={'紫燈: 多方能量激增'}
        >
          <TrafficLights color={value === 2 ? '#B02EB8' : '#301922'} />
        </Tooltip>
        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 200 }}
          placement='top'
          followCursor={true}
          title={'紅燈: 多方能量漸強'}
        >
          <TrafficLights color={value === 1 ? '#ce1126' : '#3D171C'} />
        </Tooltip>
        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 200 }}
          placement='top'
          followCursor={true}
          title={'綠燈: 空方能量漸強'}
        >
          <TrafficLights color={value === 3 ? '#7ab800' : '#212B0D'} />
        </Tooltip>
        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 200 }}
          placement='top'
          followCursor={false}
          title={'黃燈: 空方能量激增'}
        >
          <TrafficLights color={value === 4 ? '#f2af00' : '#4F3900'} />
        </Tooltip>
      </div>
    </div>
  )
})

const container = css`
  ${flex.v.crossCenter};
  width: 100%;
  height: 80px;
  border-radius: 5px;
  background-color: #454545;
  font-size: 16px;
  border: 2px solid ${'#888888'};
  box-shadow: 0px 5px 7px 1px rgba(0, 0, 0, 0.6);
`

const title = css`
  ${flex.h.allCenter};
  width: 250px;
  height: 20px;
  letter-spacing: 6px;
  background-color: #333333;
  border-radius: 0px 0px 5px 5px;
  margin-bottom: 2.5px;
  user-select: none;
`

const lightContainer = css`
  ${flex.h.allCenter};
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 0px 32px;
  border-radius: 5px;
`

export const TrafficLights = styled.div`
  ${flex.h.allCenter};
  width: 36px;
  height: 36px;
  background-color: ${props => props.color};
  border: 1px solid ${'#777777'};
  border-radius: 50px;
  box-shadow: 5px 5px 7px 1px rgba(0, 0, 0, 0.4), inset 0px 5px 5px 1px rgba(0, 0, 0, 0.5);
`
