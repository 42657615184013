import { css, MenuItem, Select } from '@mui/material'
import { find } from 'lodash'
import { memo, useState } from 'react'
import { fill, fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import { WatchSymbolToggleSingleButton } from './col_WatchSymbolToggleSingleButton'

export type WatchGroupLsitConfig = {
  groupName: string
  displayText: string
}

export const WatchSymbolToggleSelector = memo<ReactProps<{ config: WatchGroupLsitConfig[] }>>(
  function WatchSymbolToggleSelector(props) {
    const [displayText, displayTextSet] = useState(props.config[0].displayText)
    const [watchListGroupState, watchListGroupStateSet] = useState(props.config[0].groupName)
    return (
      <div
        css={css`
          ${fill_horizontal_all_center}
          gap:4px;
        `}
      >
        <div
          css={css`
            ${fill_horizontal_all_center}
            width: 50%;
          `}
        >
          自選股
        </div>
        <Select
          fullWidth
          css={css`
            height: 32px;
          `}
          size='small'
          value={displayText}
          onChange={event => {
            const eventValue = event.target.value
            displayTextSet(eventValue)
            const selectedWatchListGroup = find(props.config, item => {
              return item.displayText === eventValue
            })
            if (selectedWatchListGroup) {
              watchListGroupStateSet(selectedWatchListGroup?.groupName)
            } else {
              console.error(`找不到watchList群組`)
            }
          }}
        >
          {props.config?.map((value, index) => {
            return (
              <MenuItem
                key={index}
                value={value.displayText}
              >
                {value.displayText}
              </MenuItem>
            )
          })}
        </Select>
        <WatchSymbolToggleSingleButton
          groupName={watchListGroupState}
          maxSize={20}
        />
      </div>
    )
  },
)
