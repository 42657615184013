import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  fill_horizontal_cross_center,
  fill_vertical_cross_center,
  flex,
  jc,
} from '~/modules/AppLayout/FlexGridCss'

import { Charting } from '~/pages/heineken_template/_private/Charting'

/**
 * 主要架構的css
 *
 * TopBar SiderbrPane中的元件
 */
export const Styleds = {
  /** 側邊欄 */
  Sidebar: styled.div`
    ${fill_vertical_cross_center};
    gap: 4px;
    background-color: #141721;
    padding: 2px;
    border-left: 1px solid #555555;
  `,
  /** 頂部 */
  Topbar: styled.div`
    ${flex.h.crossCenter};
    ${jc.spaceBetween};
    padding: 0px 16px;
    width: 100%;
    height: 48px;
    background-color: #141721;
    border-bottom: 1px solid #555555;
    white-space: nowrap;
  `,
  TitleQuoteContent: styled.div`
    ${flex.h.crossCenter};
    width: 100%;
    height: 32px;
  `,
  /** 走勢圖容器 */
  TrendChartContent: styled.div`
    ${fill_horizontal_cross_center};
    height: 148px;
  `,
  /** 紅綠燈容器 */
  TrafficContent: styled.div`
    ${fill_horizontal_cross_center};
    height: 80px;
    padding: 16px;
  `,
  /** 商品模式容器 */
  PageModeContent: styled.div`
    ${fill_horizontal_cross_center};
    height: 28px;
    gap: 4px;
  `,
  /** 自選股加入按鈕容器 */
  WatchSymbolContent: styled.div`
    ${flex.h.allCenter};
    width: 100%;
    height: 28px;
  `,
  /** 商品控制板 */
  PanlContent: styled.div<{ displayTrendChart?: boolean }>`
    ${fill_vertical_cross_center};
    height: calc(100% - 288px);
    ${options =>
      options.displayTrendChart === false &&
      css`
        height: calc(100% - 136px);
      `}
  `,
  /** 策略按鈕容器 */
  StrategyButton: styled.div`
    ${fill_horizontal_cross_center};
    height: 28px;
  `,
  /** 主圖類 */
  Charting: function () {
    return (
      <div
        css={css`
          ${flex.v.allCenter};
          border-bottom: 1px solid #666666;
          height: 100%;
        `}
      >
        <Charting />
      </div>
    )
  },
}
