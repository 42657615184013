import { isEmpty } from 'lodash'
import urlcat from 'urlcat'
import { apirc } from '~/configs/apirc'
import type { Trade } from '~/modules/SDK/Trade/Trade'
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import axios, { AxiosResponse } from 'axios-0-27-2'

const prefixUrl = apirc.trade.virtualExchangeURL.baseUrl

const _axios = axios.create({
  baseURL: prefixUrl,
})

export enum VirtualExchange {
  user = 'api/user',
  /** 回傳隸屬目前使用者的所有帳戶 */
  getUserAllAccounts = 'api/user/accounts',
  accountsById = 'api/accounts/:id',
  createAccount = 'api/accounts',
  /** 回傳所有 public + private */
  allAccounts = 'api/accounts/all',
  linkedNameToAccountId = 'api/accounts/link',
  positions = 'api/accounts/:accountId/positions',
  orders = 'api/accounts/:accountId/orders',
  ordersById = 'api/accounts/:accountId/orders/:id',
  pendingOrders = 'api/accounts/:accountId/orders/pending',
  preOrders = 'api/accounts/:accountId/orders/pre',
  transactions = 'api/accounts/:accountId/transactions',
  transactionsStatements = 'api/accounts/:accountId/transactions/statements',
  dailyProfit = 'api/accounts/:accountId/dailyProfit',
}

/**
 * @private
 * @see https://virtual-exchange.futures-ai.com/swagger/index.html 一個uid
 * 1. 只能 createUser 一次
 * 2. 可以多個 createAccount
 */
export const tradeAPI = {
  /** 初始化，要先登入 firebase 再來初始化對應的 tradeUser */
  async createUser(userName: string) {
    const token = useMeStore.getState().meFirebaseState?.jwt
    const body = {
      name: userName,
    }
    return _axios({
      method: 'post',
      url: VirtualExchange.user,
      data: body,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then(() => {
      this.getUser()
    })
  },
  async changeUserName(userName: string) {
    const token = useMeStore.getState().meFirebaseState?.jwt
    const body = {
      name: userName,
    }
    return _axios({
      method: 'post',
      url: VirtualExchange.user,
      data: body,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then(res => res.data)
  },
  /** 透過目前 firebase 的 token，查詢目前的 tradeUser */
  async getUser() {
    const token = useMeStore.getState().meFirebaseState?.jwt
    return _axios
      .get<Trade.User>(VirtualExchange.user, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        if (res.status == 200 && res?.data?.uid == useMeStore.getState().meFirebaseState?.uid) {
          useTradeStore.setState({ hasTradeUser: true, user: res.data })
        }
      })
  },
  /** User 底下創建帳號，只需要帶名字，可以建立多個 */
  async createAccount(accountName: string) {
    const token = useMeStore.getState().meFirebaseState?.jwt
    const body = {
      name: accountName,
    }
    return _axios({
      method: 'post',
      url: VirtualExchange.createAccount,
      data: body,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then(() => this.getCurrentUserAccounts())
  },
  /** 取得該 user 多個 accounts */
  async getCurrentUserAccounts() {
    const token = useMeStore.getState().meFirebaseState?.jwt
    const accountId = useTradeStore.getState().currentSelectAccountId
    return _axios
      .get<Trade.UserAccount[]>(VirtualExchange.getUserAllAccounts, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        if (!isEmpty(res.data)) {
          const currentAccount = res.data.find(acc => acc.id === accountId)
          useTradeStore.setState({
            accounts: res.data,
            currentSelectAccountId: currentAccount?.id ?? res.data[0].id,
          })
        }
      })
  },
  /** 取得該 公開 accounts */
  async getAllPublicAccounts() {
    return _axios
      .get<Trade.UserAccount[]>(VirtualExchange.getUserAllAccounts, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer fixedtoken`,
        },
      })
      .then(res => {
        if (!isEmpty(res.data))
          useTradeStore.setState({
            publicAccounts: res.data,
          })
      })
  },
  /** 取得該 user 某個單一 account 細節，equity, balance, unrealizedPnL */
  async getAccountById(isPublic?: boolean) {
    const token = useMeStore.getState().meFirebaseState?.jwt
    const accountId = useTradeStore.getState().currentSelectAccountId
    const url = urlcat(VirtualExchange.accountsById, { id: accountId })
    return _axios
      .get<Trade.UserAccount>(url, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${isPublic ? 'fixedtoken' : token}`,
        },
      })
      .then(res => {
        if (res.data) {
          useTradeStore.setState({
            currentSelectAccountBalance: res.data?.balance,
            currentSelectAccountEquity: res.data.equity,
            currentSelectAccountUnrealizedPnL: res.data.unrealizedPnL,
          })
        }
      })
  },
  async deleteAccount() {
    const token = useMeStore.getState().meFirebaseState?.jwt
    const accountId = useTradeStore.getState().currentSelectAccountId
    const url = urlcat(VirtualExchange.accountsById, { id: accountId })
    return _axios
      .delete<Trade.UserAccount[]>(url, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        this.getCurrentUserAccounts()
      })
  },

  async getDailyProfit(isPublic: boolean, beginDatetime: string, endDatetime: string) {
    const token = useMeStore.getState().meFirebaseState?.jwt
    const accountId = useTradeStore.getState().currentSelectAccountId
    const url = urlcat(VirtualExchange.dailyProfit, { accountId, beginDatetime, endDatetime })

    return _axios
      .get<Trade.DailyProfit[]>(url, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${isPublic ? 'fixedtoken' : token}`,
        },
      })
      .then(res => {
        useTradeStore.setState({
          dailyProfit: res.data,
        })
      })
  },
  /** 送出委託訊號 */
  async createOrder(order: Trade.OrderDraft) {
    const token = useMeStore.getState().meFirebaseState?.jwt
    const accountId = useTradeStore.getState().currentSelectAccountId

    return _axios({
      method: 'post',
      url: urlcat(VirtualExchange.orders, { accountId }),
      data: order,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  },
  /** 查詢所有委託單 */
  async getOrders(isPublic?: boolean) {
    const token = useMeStore.getState().meFirebaseState?.jwt
    const accountId = useTradeStore.getState().currentSelectAccountId
    return _axios
      .get<Trade.Order[]>(urlcat(VirtualExchange.orders, { accountId }), {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${isPublic ? 'fixedtoken' : token}`,
        },
      })
      .then(res => {
        useTradeStore.setState({
          orders: res.data,
        })
      })
  },
  /** 查詢還在等待成交的委託單 */
  async getPendingOrders(isPublic?: boolean) {
    const token = useMeStore.getState().meFirebaseState?.jwt
    const accountId = useTradeStore.getState().currentSelectAccountId
    return _axios
      .get<Trade.Order[]>(urlcat(VirtualExchange.pendingOrders, { accountId }), {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${isPublic ? 'fixedtoken' : token}`,
        },
      })
      .then(res => {
        useTradeStore.setState({
          pendingOrders: res.data,
        })
      })
  },
  /** 刪除委託單 */
  async deleteOrder(orderId: string) {
    const token = useMeStore.getState().meFirebaseState?.jwt
    const accountId = useTradeStore.getState().currentSelectAccountId
    const url = urlcat(VirtualExchange.ordersById, {
      accountId,
      id: orderId,
    })
    return _axios
      .delete(url, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        this.getPendingOrders()
        this.getOrders()
      })
  },
  /** 交易明細，可指定商品，不指定預設回傳全部商品。 */
  async getTransactions(isPublic?: boolean) {
    const accountId = useTradeStore.getState().currentSelectAccountId
    const url = urlcat(VirtualExchange.transactions, { accountId })
    const token = useMeStore.getState().meFirebaseState?.jwt
    return _axios
      .get<Trade.Transaction[]>(url, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${isPublic ? 'fixedtoken' : token}`,
        },
      })
      .then(res => {
        useTradeStore.setState({
          transactions: res.data,
        })
      })
  },
  /** 取得此帳號交易商品的部位，可指定商品，不指定預設回傳全部商品。 */
  async getPositions(isPublic?: boolean) {
    const accountId = useTradeStore.getState().currentSelectAccountId
    const url = urlcat(VirtualExchange.positions, { accountId, realtime: true })
    const token = useMeStore.getState().meFirebaseState?.jwt
    return _axios
      .get<Trade.Position[]>(url, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${isPublic ? 'fixedtoken' : token}`,
        },
      })
      .then(res => {
        useTradeStore.setState({
          positions: res.data,
        })
      })
  },
  /** 查詢平倉損益紀錄 */
  async getStatements(options?: Trade.StatementAPIOption, isPublic?: boolean) {
    const token = useMeStore.getState().meFirebaseState?.jwt
    // fallback
    const accountId = useTradeStore.getState().currentSelectAccountId
    return _axios
      .get<Trade.Statement[]>(
        urlcat(VirtualExchange.transactionsStatements, { accountId, ...options }),
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${isPublic ? 'fixedtoken' : token}`,
          },
        },
      )
      .then(res => {
        useTradeStore.setState({
          statements: res.data,
        })
      })
  },
}
