import { css } from '@emotion/react'
import { memo } from 'react'
import { proxy, useSnapshot } from 'valtio'
import { SignalSymbolArray, useFirebaseValue } from '~/hooks/useFirebaseValue'
import {
  fill_horizontal_cross_center,
  fill_vertical_cross_center,
  flex,
} from '~/modules/AppLayout/FlexGridCss'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { store } from '~/pages/heineken_template/_private/store'

//大師選股
export const useFirebasePosition = proxy({
  positionType: 'long' as 'long' | 'short',
})

export const Winner98_MasterSymbol = memo(function Winner98_MasterSymbol() {
  const firebasePosition = useSnapshot(useFirebasePosition)

  const long = useFirebaseValue<SignalSymbolArray>('winner98_signal', 'long')
  const short = useFirebaseValue<SignalSymbolArray>('winner98_signal', 'short')

  const symbol = firebasePosition.positionType === 'long' ? long?.symbols : short?.symbols

  return (
    <div
      css={css`
        ${fill_vertical_cross_center}
        gap:4px;
      `}
    >
      <div
        css={css`
          ${fill_horizontal_cross_center}
          height: 28px;
          gap: 4px;
        `}
      >
        <FuiButton.Display
          variant='long'
          active={firebasePosition.positionType === 'long'}
          onClick={event => {
            useFirebasePosition.positionType = 'long'
          }}
        >
          多方操作
        </FuiButton.Display>
        <FuiButton.Display
          variant='short'
          active={firebasePosition.positionType === 'short'}
          onClick={event => {
            useFirebasePosition.positionType = 'short'
          }}
        >
          空方操作
        </FuiButton.Display>
      </div>
      <div
        css={css`
          width: 100%;
          height: calc(100% - 40px);
        `}
      >
        <SimpleQuoteListInteractWithChart
          data={symbol ?? []}
          chart={store.charting}
        />
      </div>
    </div>
  )
})
