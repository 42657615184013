import { useIndicatorStore } from '~/store/useIndicatorStore'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { usePageWinner98Store } from '~/pages/winner98/modules/usePageWinner98Store'
import { useSwitchChartPositionStore } from '~/modules/cons-misc/SwitchChartPositionButton'
import { useChartPositionStore } from '~/modules/cons-misc/mode-buttons/ChartPositionButton'

export const winner98_signal2 = createIndicator({
  displayName: '帝王決策2',
  id: 'winner98signal2',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const positionType = useChartPositionStore.positionType
      const type = usePageWinner98Store.getState().strategyType
      const state = useIndicatorStore.getState()
      const time = this.PineJS.Std.time(this._context)
      const localTimeVar = new Date().getTime()
      const close = this.ohlc.closeArray
      const volume = this.PineJS.Std.volume(this._context)

      const bState = this._context.new_var()
      const entryPrice = this._context.new_var()

      bState.get(2)
      entryPrice.get(2)

      let B_signal = NaN
      let S_signal = NaN
      let pb1 = NaN
      let ps1 = NaN
      //let pb2 = NaN
      //let ps2 = NaN

      const kbarLoadingTimeMs = 3000
      const isInitialized =
        new Date().getTime() >
        parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + kbarLoadingTimeMs

      const ma66 = this.PineJS.Std.sma(close, 66, this._context)
      const ma22 = this.PineJS.Std.sma(close, 22, this._context)

      const conditionLong = ma22 > ma66 && ma22 / ma66 < 1.02 && close > ma22
      const conditionShort = ma22 < ma66 && ma66 / ma22 < 1.02 && close < ma22

      if (conditionLong && positionType !== 'short') {
        bState.set(1)
      } else if (conditionShort && positionType !== 'long') {
        bState.set(-1)
      }

      //---------------------------------------------
      if (bState.get(0) === 1 && bState.get(1) !== 1) {
        B_signal = 1
        entryPrice.set(close.get(0))
      }

      if (bState.get(0) === -1 && bState.get(1) !== -1) {
        S_signal = 1
        entryPrice.set(close.get(0))
      }

      if (bState.get(1) === 1 && bState.get(0) === 1) {
        if (close < ma22 && close > entryPrice.get(0) * 1.05) {
          bState.set(0)
          pb1 = 1
        }

        if (close < entryPrice.get(0) * 0.97) {
          bState.set(0)
        }
      }
      if (bState.get(1) === -1 && bState.get(0) === -1) {
        if (close > ma22 && close < entryPrice.get(0) * 0.95) {
          bState.set(0)
          ps1 = 1
        }
        if (close > entryPrice.get(0) * 1.03) {
          bState.set(0)
        }
      }
      const colorIndex = bState.get(0) > 0 ? 0 : bState.get(0) === 0 ? 2 : 1

      return [B_signal, S_signal, pb1, ps1, 0, 0, type !== 2 ? colorIndex : NaN, entryPrice.get(0)]
    },
  },
  metainfo: {
    //宣告
    plots: [
      {
        id: 'plot_4',
        type: 'shapes',
      },
      {
        id: 'plot_5',
        type: 'shapes',
      },
      {
        id: 'plot_6',
        type: 'chars',
      },
      {
        id: 'plot_7',
        type: 'chars',
      },
      {
        id: 'plot_8',
        type: 'chars',
      },
      {
        id: 'plot_9',
        type: 'chars',
      },
      {
        id: 'plot_12',
        type: 'bar_colorer',
        palette: 'palette_0',
      },
      {
        id: 'plot_13',
        type: 'line',
      },
    ],
    palettes: {
      palette_0: {
        colors: {
          0: {
            color: '#9C27B0',
            width: 1,
            style: 0,
          },
          1: {
            color: '#FDD835',
            width: 1,
            style: 0,
          },
          2: {
            color: '#BDBDBD',
            width: 1,
            style: 0,
          },
        },
      },
    },
    defaults: {
      //樣式調整
      styles: {
        plot_4: {
          color: '#CC27B0',
          textColor: '#CC27B0',
          transparency: 0,
          visible: true,
        },
        plot_5: {
          color: '#FAF835',
          textColor: '#FAF835',
          transparency: 0,
          visible: true,
        },
        plot_6: {
          color: '#ffffff',
          textColor: '#ffffff',
          transparency: 0,
          visible: true,
        },
        plot_7: {
          color: '#ffffff',
          textColor: '#ffffff',
          transparency: 0,
          visible: true,
        },
        plot_8: {
          color: '#ffff00',
          textColor: '#ffff00',
          transparency: 100,
          visible: true,
        },
        plot_9: {
          color: '#ffff00',
          textColor: '#ffff00',
          transparency: 100,
          visible: true,
        },
        plot_13: {
          linestyle: 2,
          linewidth: 2,
          plottype: 7,
          trackPrice: !1,
          transparency: 35,
          visible: !1,
          color: '#ff0000',
        },
      },
    },

    styles: {
      plot_4: {
        isHidden: false,
        location: 'BelowBar',
        plottype: 'shape_triangle_up',
        size: 'small',
        text: 'Buy',
        title: '三角形',
      },
      plot_5: {
        isHidden: false,
        location: 'AboveBar',
        plottype: 'shape_triangle_down',
        size: 'small',
        text: 'Short',
        title: '三角形',
      },
      plot_6: {
        isHidden: false,
        location: 'AboveBar',
        char: '★',
        size: 'small',
        title: '-',
        text: '',
      },
      plot_7: {
        isHidden: false,
        location: 'BelowBar',
        char: '★',
        size: 'small',
        title: '-',
        text: '',
      },
      plot_8: {
        isHidden: false,
        location: 'AboveBar',
        char: '',
        size: 'small',
        title: '-',
      },
      plot_9: {
        isHidden: false,
        location: 'BelowBar',
        char: '',
        size: 'small',
        title: '-',
      },
      plot_12: {
        title: '紫金',
        histogramBase: 0,
      },
      plot_13: {
        title: '危機與轉機線',
        histogramBase: 0,
        joinPoints: !1,
      },
    },

    is_price_study: !0,
    inputs: [],
    scriptIdPart: '',
  },
})
