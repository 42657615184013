import { proxy } from 'valtio'
import { FuiTabs2 } from '~/pages/heineken_template/components/FuiTabs2'
import { FuiUserAvatar } from '~/pages/heineken_template/components/FuiUserAvatar'

export const winner98_agentStore = proxy({
  tabs1: new FuiTabs2(['主力選股', '熱門股', '大師選股', '自選股']),
  tabs2: new FuiTabs2(['寶藏', '持有', '收割']),
  tabs3: new FuiTabs2(['群組1', '群組2', '群組3']),
  strategy: 'WINNER 1' as 'WINNER 1' | 'WINNER 2' | 'WINNER 3' | string,
  displayTrendChart: false as boolean,
  userStockGroup: 1 as 1 | 2 | 3,
  userAvatar: new FuiUserAvatar(),
})

export type StockGroupName =
  | 'winner98_stock_group_1'
  | 'winner98_stock_group_2'
  | 'winner98_stock_group_3'
