import { memo } from 'react'
import { useCommonSymbolListStore } from '~/modules/SDK/Symbol/useCommonSymbolListStore'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import { store } from '~/pages/heineken_template/_private/store'

//熱門股
export const Winner98_PopularSymbol = memo(function Winner98_PopularSymbol() {
  const tw50 = useCommonSymbolListStore.getState().tw50
  return (
    <SimpleQuoteListInteractWithChart
      data={tw50 ?? []}
      chart={store.charting}
    />
  )
})
