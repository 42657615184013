import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const winner98_trend_line = createIndicator({
  displayName: '紫金趨勢(PG)',
  id: 'winner98trendline',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const in1 = 60
      const in2 = 20

      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)
      const sma = this.PineJS.Std.sma(close_array, 240, this._context)
      const sma1 = this.PineJS.Std.ema(close_array, in1, this._context) / sma
      const sma2 = this.PineJS.Std.ema(close_array, in2, this._context) / sma

      const colorIndex = sma1 - sma2 >= 0 ? 1 : 0

      return [sma1, sma2, 1, colorIndex]
    },
  },
  metainfo: {
    //宣告
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
      {
        id: 'plot_2',
        type: 'line',
      },
      {
        id: 'plot_3',
        type: 'colorer',
        target: 'filledAreaId1',
        palette: 'paletteId1',
      },
    ],

    filledAreas: [
      {
        id: 'filledAreaId1',
        objAId: 'plot_1',
        objBId: 'plot_0',
        type: 'plot_plot',
        title: '趨勢指標',
        palette: 'paletteId1',
      },
    ],

    palettes: {
      paletteId1: {
        valToIndex: {
          0: 0,
          1: 1,
        },
        colors: {
          0: {
            name: '多方趨勢',
          },
          1: {
            name: '空方趨勢',
          },
        },
      },
    },

    defaults: {
      //樣式調整
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 100,
          visible: !0,
          color: '#11ff11',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 100,
          visible: !0,
          color: '#ff1111',
        },
        plot_2: {
          linestyle: 2,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 100,
          visible: !0,
          color: '#aaaaaa',
        },
      },
      //填充區域
      filledAreasStyle: {
        filledAreaId1: {
          color: '#0000ff',
          transparency: 10,
          visible: !0,
        },
      },
      palettes: {
        paletteId1: {
          colors: {
            0: {
              color: '#872499',
              width: 1,
              style: 0,
            },
            1: {
              color: '#ffee58',
              width: 1,
              style: 1,
            },
          },
        },
      },
      // inputs: {
      //   in_0: 48,
      //   in_1: 26,
      // },
    },

    is_price_study: !1,
    inputs: [
      // {
      //   id: 'in_0',
      //   name: '參數1',
      //   defval: 48,
      //   type: 'integer',
      //   min: 1,
      //   max: 360,
      // },
      // {
      //   id: 'in_1',
      //   name: '參數2',
      //   defval: 26,
      //   type: 'integer',
      //   min: 1,
      //   max: 360,
      // },
    ],
    scriptIdPart: '',
  },
})
