import { State } from 'zustand'
import type { Trade } from '~/modules/SDK/Trade/Trade'
import { createStore } from '~/store/createStore'

interface UseTradeStoreStateBase extends State {
  brokerType: 'Brokerless' | 'Sinopac'
  accounts: Trade.UserAccount[]
  publicAccounts: Trade.UserAccount[]
  /**
   * 利用 currentSelectAccountId
   *
   * 去控制另外三個：
   *
   * CurrentSelectAccountBalance
   *
   * CurrentSelectAccountEquity
   *
   * CurrentSelectAccountUnrealizedPnL
   */
  currentSelectAccountId: number | undefined
  /** 權益總值 */
  currentSelectAccountBalance: number | undefined
  /** 權益數 */
  currentSelectAccountEquity: number | undefined
  /** 未實現損益 */
  currentSelectAccountUnrealizedPnL: number | undefined
  user: Trade.User | undefined
  hasTradeUser: boolean
  positions: Trade.Position[]
  transactions: Trade.Transaction[]
  /** 未成交委託清單 */
  pendingOrders: Trade.Order[]
  /** 所有委託清單 */
  orders: Trade.Order[]
  /** 帳戶每日損益變化 */
  dailyProfit: Trade.DailyProfit[]
  /** 對帳紀錄 */
  statements: Trade.Statement[]
  /** 使用者預期交易口數 */
  qty: Trade.OrderDraft['qty']
  /** 使用者預期交易價格 */
  price: Trade.OrderDraft['price']
  /**
   * 以下是 https://strategy-center.e01.futures-op.com/swagger/index.html 多出來的部分
   *
   * 上面多為重複，所以直接沿用 useTradeStore
   */
  strategyAuth: string | undefined
  strategyAuthUserName: string | undefined
  strategies: Trade.Strategy[]
  currentStrategyName: string | undefined
}

export const useTradeStore = createStore<UseTradeStoreStateBase>((set, get) => {
  return {
    brokerType: 'Brokerless',
    accounts: [],
    publicAccounts: [],
    currentSelectAccountId: undefined,
    currentSelectAccountBalance: undefined,
    currentSelectAccountEquity: undefined,
    currentSelectAccountUnrealizedPnL: undefined,
    user: undefined,
    hasTradeUser: false,
    positions: [],
    transactions: [],
    pendingOrders: [],
    orders: [],
    dailyProfit: [],
    statements: [],
    account: '',
    balance: 0,
    qty: 1,
    price: 'M',
    /**
     * 以下是 https://strategy-center.e01.futures-op.com/swagger/index.html 多出來的部分
     *
     * 上面多為重複，所以直接沿用 useTradeStore
     */
    strategyAuth: undefined,
    strategyAuthUserName: undefined,
    strategies: [],
    currentStrategyName: undefined,
  }
})
