import { css } from '@emotion/react'
import { memo } from 'react'
import { proxy, useSnapshot } from 'valtio'
import {
  fill,
  fill_horizontal_cross_center,
  fill_vertical_cross_center,
  flex,
} from '~/modules/AppLayout/FlexGridCss'
import { useVirtualHoldingSort } from '~/modules/investment-consultant/signalrQuoteSort/useSignalrSortCondition'
import {
  HoldingQuoteListInteractWithChart,
  SimpleQuoteListInteractWithChart,
} from '~/modules/symbolQuote/simple'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { store } from '~/pages/heineken_template/_private/store'
import { winner98_agentStore } from '../winner98_agentStore'
import {
  useVirtualAccountHolding,
  useVirtualAccountOrderPre,
} from '~/modules/virtualExchange/useVirtualExchangeResource'

export const useDefaultPosition = proxy({
  positionType: 'long' as 'long' | 'short',
})

export const Winner98_DefaultListSymbol = memo<ReactProps>(function Winner98_DefaultListSymbol() {
  return (
    <div
      css={css`
        ${fill_vertical_cross_center}
        gap:4px;
      `}
    >
      <VirtualEquityButtonGroup />
      <VirtualLongPositionButtonGroup />
      <div
        css={css`
          ${fill_vertical_cross_center};
          height: calc(100% - 64px);
        `}
      >
        <SymbolList />
      </div>
    </div>
  )
})

const VirtualEquityButtonGroup = memo(function VirtualEquityButtonGroup() {
  const defaultPosition = useSnapshot(useDefaultPosition)
  const isLongState = defaultPosition.positionType === 'long'
  const isShortState = defaultPosition.positionType === 'short'

  return (
    <div
      css={css`
        ${fill_horizontal_cross_center};
        height: 28px;
        gap: 4px;
      `}
    >
      <FuiButton.Display
        variant='long'
        active={isLongState}
        onClick={event => {
          useDefaultPosition.positionType = 'long'
        }}
      >
        多方標的
      </FuiButton.Display>
      <FuiButton.Display
        variant='short'
        active={isShortState}
        onClick={event => {
          useDefaultPosition.positionType = 'short'
        }}
      >
        空方標的
      </FuiButton.Display>
    </div>
  )
})

const VirtualLongPositionButtonGroup = memo(function VirtualLongPositionButtonGroup() {
  const defaultPosition = useSnapshot(useDefaultPosition)
  const isLong = defaultPosition.positionType === 'long'
  const positionString = isLong ? '多' : '空'

  return (
    <div
      css={css`
        ${fill_horizontal_cross_center}
        height: 28px;
        gap: 4px;
      `}
    >
      <winner98_agentStore.tabs2.WithButton to='寶藏'>
        <FuiButton.Display>{positionString}方寶藏</FuiButton.Display>
      </winner98_agentStore.tabs2.WithButton>

      <winner98_agentStore.tabs2.WithButton to='持有'>
        <FuiButton.Display>{positionString}方持有</FuiButton.Display>
      </winner98_agentStore.tabs2.WithButton>

      <winner98_agentStore.tabs2.WithButton to='收割'>
        <FuiButton.Display>{positionString}方收割</FuiButton.Display>
      </winner98_agentStore.tabs2.WithButton>
    </div>
  )
})

const SymbolList = memo<ReactProps>(function LongSymbolList() {
  const defaultPosition = useSnapshot(useDefaultPosition)
  const state = useSnapshot(winner98_agentStore).strategy

  const strategyType = () => {
    if (state === 'WINNER 1') {
      return 'winner98_'
    } else if (state === 'WINNER 2') {
      return 'winner98_ma_'
    } else if (state === 'WINNER 3') {
      return 'winner98_roc_'
    } else return 'winner98_'
  }

  //多方----------------------------------------------
  //原始
  const aiLongHold = useVirtualAccountHolding(strategyType() + 'long')
  const aiOrderLongEntry = useVirtualAccountOrderPre(strategyType() + 'long', 'B')
  const aiOrderLongExit = useVirtualAccountOrderPre(strategyType() + 'long', 'S')
  //sort
  const aiLongHoldSort = useVirtualHoldingSort(aiLongHold ?? [])
  //Map symbolNumber
  const aiNumberLongEntry = aiOrderLongEntry?.map(item => item.symbol)
  const aiNumberLongExit = aiOrderLongExit?.map(item => item.symbol)

  //空方----------------------------------------------
  //原始
  const aiShorHold = useVirtualAccountHolding(strategyType() + 'short')
  const aiOrderShortEntry = useVirtualAccountOrderPre(strategyType() + 'short', 'S')
  const aiOrderShortExit = useVirtualAccountOrderPre(strategyType() + 'short', 'B')
  //sort
  const aiShortHoldSort = useVirtualHoldingSort(aiShorHold ?? [])
  //Map symbolNumber
  const aiNumberShortEntry = aiOrderShortEntry?.map(item => item.symbol)
  const aiNumberShortExit = aiOrderShortExit?.map(item => item.symbol)

  //-------------------------------------------------
  const isLongState = defaultPosition.positionType === 'long'
  const entrySymbol = isLongState ? aiNumberLongEntry : aiNumberShortEntry
  const holdingSymbol = isLongState ? aiLongHoldSort : aiShortHoldSort
  const exitSymbol = isLongState ? aiNumberLongExit : aiNumberShortExit

  return (
    <div css={fill}>
      <winner98_agentStore.tabs2.WithContent for='寶藏'>
        <SimpleQuoteListInteractWithChart
          data={entrySymbol ?? []}
          chart={store.charting}
        />
      </winner98_agentStore.tabs2.WithContent>

      <winner98_agentStore.tabs2.WithContent for='持有'>
        <HoldingQuoteListInteractWithChart
          data={holdingSymbol}
          chart={store.charting}
        />
      </winner98_agentStore.tabs2.WithContent>

      <winner98_agentStore.tabs2.WithContent for='收割'>
        <SimpleQuoteListInteractWithChart
          data={exitSymbol ?? []}
          chart={store.charting}
        />
      </winner98_agentStore.tabs2.WithContent>
    </div>
  )
})
