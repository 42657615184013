import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { BsFillPlusCircleFill } from 'react-icons/bs'
import { VscError } from 'react-icons/vsc'
import { useSnapshot } from 'valtio'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList'
import { store } from '~/pages/heineken_template/_private/store'

const classes = {
  Root: styled.div<{ variant?: 'long' | 'short'; isJoined?: boolean }>`
    ${fill_horizontal_all_center}
    background-color: ${props =>
      props.isJoined === true ? buttonClasses.hadJoined : buttonClasses.notJoined};
    gap: 4px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    ${options => {
      const long =
        options.variant === 'long' &&
        css`
          background-color: #994444;
        `
      const short =
        options.variant === 'short' &&
        css`
          background-color: #449944;
        `

      return css([long, short])
    }}
  `,
}

export const WatchSymbolToggleSingleButton = memo<
  ReactProps<{
    groupName: string
    type?: 'long' | 'short'
    describeText?: string
    maxSize?: number
  }>
>(function WatchSymbolToggleSingleButton(props) {
  const {
    state: { symbolListArray: watchList },
    acts,
  } = useSymbolWatchList(props.groupName)
  const symbolString = useSnapshot(store.charting).symbol
  const hadSymbol = watchList?.includes(symbolString)
  const icon = hadSymbol ? <VscError /> : <BsFillPlusCircleFill />
  const describeText = props.describeText ?? ''
  const defaultType = props.type === undefined
  const longType = props.type === 'long'
  const shortType = props.type === 'short'
  const typeString = defaultType ? describeText : longType ? '多方' : '空方'

  return (
    <classes.Root
      variant={props.type}
      isJoined={hadSymbol}
      className={props.className}
      onClick={event => {
        if (hadSymbol) {
          acts.removeSymbol(symbolString)
        }
        if (!hadSymbol) {
          acts.addSymbol(symbolString, props.maxSize ?? 10000)
        }
      }}
    >
      {icon}
      {hadSymbol ? '移除' + typeString + '自選' : '加入' + typeString + '自選'}
    </classes.Root>
  )
})
const buttonClasses = {
  hadJoined: '#994444',
  notJoined: '#448899',
}

export default {
  display: WatchSymbolToggleSingleButton,
  classes,
  buttonClasses,
}
