import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '~/pages/heineken_template/_private/store'
import { winner98_trend_line } from '~/trades/indicators/winner98/winner98_trend_line'
import { winner98_signal } from '~/trades/indicators/winner98/winner98_signal'
import { winner98_signal2 } from '~/trades/indicators/winner98/winner98_signal2'
import { winner98_signal3 } from '~/trades/indicators/winner98/winner98_signal3'

export const winner98_strategiesGroup = {
  /** 主要策略集 */
  main: [
    {
      displayName: 'WINNER 1',
      indicators: [winner98_trend_line, winner98_signal],
      panesRatio: [30],
    },
    {
      displayName: 'WINNER 2',
      indicators: [winner98_trend_line, winner98_signal2],
      panesRatio: [30],
    },
    {
      displayName: 'WINNER 3',
      indicators: [winner98_trend_line, winner98_signal3],
      panesRatio: [30],
    },
  ] as ChartTypes.StrategyConfig[],
}

export const winner98_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...[winner98_trend_line, winner98_signal, winner98_signal2, winner98_signal3],
  ]

  store.charting.initStrategy({
    configs: [...winner98_strategiesGroup.main],
  })
}
